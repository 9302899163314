import React from 'react'

import aboutStyle from './about.module.scss'
import '../scss/_breakpoints.scss';

class About extends React.PureComponent {
  render() {
    return (
      <div className={aboutStyle.aboutContainer}>
        <div className={aboutStyle.terminal}>
          <div className={aboutStyle.terminalHeader}>
            <div className={[aboutStyle.headerButton, aboutStyle.red].join(' ')} />
            <div className={[aboutStyle.headerButton, aboutStyle.yellow].join(' ')} />
            <div className={[aboutStyle.headerButton, aboutStyle.green].join(' ')} />
          </div>
          <div className={aboutStyle.terminalWindow}>
            <Statements statements={this.props.statements} />
          </div>
        </div>
      </div>
    );
  }
}

class Statements extends React.PureComponent {
  render() {
    return (
      <div>
        {this.props.statements.map((statement, index) => {
          return <Statement statement={statement} key={index} />;
        })}
        <div className={aboutStyle.statement}>
          <div className={aboutStyle.inputStatement}>
            <span>&nbsp;</span>
          </div>
        </div>
      </div>
    );
  }
}

class Statement extends React.PureComponent {
  render() {
    return (
      <div className={aboutStyle.statement}>
        <div className={aboutStyle.inputStatement}>{this.props.statement.input}</div>
        <div
          className={aboutStyle.returnStatement}
          dangerouslySetInnerHTML={{ __html: this.props.statement.return }}
        />
      </div>
    );
  }
}

export default About
