import React from 'react'
import Scrollchor from 'react-scrollchor';
import {Link, Trans} from 'gatsby-plugin-react-i18next';

import Typewriter from './typewriter'
import heroStyle from './hero.module.scss'


const Hero = () => {
  return (
    <div className={heroStyle.heroContainer}>
      <Typewriter className={heroStyle.heroTitle} text={"Emre Ekener"} />
      <div className={heroStyle.heroDescription}>
        <Trans>First Line</Trans><br />
        <Trans>Second Line</Trans>{' '}
        <Link to="/services"><Trans>Third Line</Trans></Link> <Trans>Fourth Line</Trans> <Scrollchor to="contact"><Trans>Fifth Line</Trans></Scrollchor> <Trans>Sixth Line</Trans>
      </div>
    </div>
  )
}

export default Hero
