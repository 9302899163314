import React from 'react'
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next'

import Layout from '../components/layout'
import Hero from '../components/hero'
import About from '../components/about'
import Contact from '../components/contact'
import Head from '../components/head'

const IndexPage = () => {
  const {t} = useTranslation()
  let statements = [
    {
      input: t("Location"),
      return: t("City"),
    },
    {
      input: t('Contact Info'),
      return:
        '["<a href="mailto:emre@ekener.io">emre@ekener.io</a>", "<a rel="noopener" href="https://www.linkedin.com/in/emre-ekener-396a31148/">LinkedIn</a>", "<a rel="noopener" href="https://github.com/eekener">github</a>"]',
    },
    {
      input: t("Interests"),
      return: t("Hobbies"),
    },
    {
      input: t("Education"),
      return:
        t("University"),
    },
    {
      input: t("Skills"),
      return:
        '["React", "Sass", "JavaScript", "PHP", "WordPress", "Gatsby", "HTML", "CSS", "GIT"]',
    },
  ]
  return (
    <Layout>
      <Head title="Home" />
      <Hero />
      <About statements={statements} />
      <h2 id="contact" className="section-title">
        <Trans>Contact</Trans>
      </h2>
      <Contact />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
